import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import Loader from './Loader';

PrivateRoute.propTypes = {
    children: PropTypes.any,
};
export default function PrivateRoute({children}) {
    const { token } = useSelector(state => state.user);
    return token ? (<Suspense fallback={<Loader />}>{children}</Suspense>) : (<Navigate to="/login" replace state={{ from: window.location.pathname }} />)
}