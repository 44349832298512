import { createSlice } from '@reduxjs/toolkit'

const initialState = {};

export const workspaceSlice = createSlice({
    name: 'workspace',
    initialState,
    reducers: {
        newWorkspace: (state, action) => ({ ...action.payload }),
        updateWorkspace: (state, action) => ({ ...state.workspace, ...action.payload }),
        resetWorkspace: () => initialState
    },
})

// Action creators are generated for each case reducer function
export const { newWorkspace, updateWorkspace, resetWorkspace } = workspaceSlice.actions

export default workspaceSlice.reducer